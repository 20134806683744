<template>
<v-card v-if="object" class="pa-3 cycle-range-picker" elevation="4" width="230" height="180">
  <v-container fluid>
    <v-row class="justify-center">
      <Calendar @pick="changeCycle" :current="object.ob_cycle" :isWhole="false" />
    </v-row>
    <v-row class="mt-6">
      <v-divider></v-divider>
    </v-row>
    <v-row class="align-center ml-1">
      <date-range-picker
        ref="picker"
        opens="center"
        :locale-data="{ 
            firstDay: 1,
            format: 'yyyy-mm-dd',
            applyLabel: '确定',
            cancelLabel: '取消',
            daysOfWeek: ['日', '一', '二', '三', '四', '五', '六'],
            monthNames: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        }"
        minDate="2018-01-01" maxDate="2050-12-31"
        :dateRange="{startDate: object.ob_start_date, endDate: object.ob_end_date }"
        :ranges="false"
        @update="datePicker"
        :append-to-body="true"
      >
          <template v-slot:input="picker" style="min-width: 350px;">
            <div class="text-catpion grey--text">开始日期 : <span class="black--text">{{ picker.startDate | newFormatDate }}</span></div>
            <div class="text-catpion grey--text">结束日期 : <span class="black--text">{{ picker.endDate | newFormatDate }}</span></div>
          </template>
      </date-range-picker>      
    </v-row>
    <v-row class="justify-end mt-5">
      <v-btn @click="cancel" class="mx-1" small>取消</v-btn>
      <v-btn @click="ok" dark class="mx-1" small color="primary">确定</v-btn>
    </v-row>
  </v-container>
</v-card>
</template>
<script>
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import moment from 'moment';

export default {
  name: 'CycleRangePicker',
  components: {
    Calendar: () => import('@/components/common/Calendar.vue'),
    DateRangePicker,
  },
  props: ['object'],
  methods: {
    changeCycle(e) {
      this.object.ob_cycle = e.cycle;
      if(e.cycle.includes('/')) {
        const cycle = e.cycle.split('/');
        const year = cycle[0];
        const quarter = cycle[1];
        if(quarter !== '0') {
          let start = new Date(year, (quarter-1)*3, 1)
          let end = new Date(year,start.getMonth() + 3, 0)
          start = start.getFullYear() + '-' + (start.getMonth() + 1) + '-' + start.getDate();
          end = end.getFullYear() + '-' + (end.getMonth() + 1) + '-' + end.getDate();
          this.object.ob_start_date = start;
          this.object.ob_end_date = end;  
        } else {
          this.object.ob_start_date = `${year}-01-02`;
          this.object.ob_end_date = `${Number(year)+1}-01-01`;
        }         
      }
      if(e.cycle.includes('-')) {
        const cycle = e.cycle.split('-');
        const year = cycle[0];
        const month = cycle[1];
        let start = new Date(year, month-1, 1)
        let end = new Date(year, month, 0)
        start = start.getFullYear() + '-' + (start.getMonth() + 1) + '-' + start.getDate();
        end = end.getFullYear() + '-' + (end.getMonth() + 1) + '-' + end.getDate();
        this.object.ob_start_date = start;
        this.object.ob_end_date =end;
      }
    },
    datePicker(e) {
      let start = e.startDate;
      let end = e.endDate;
      start = new Date(start);
      end = new Date(end);
      start = start.getFullYear() + '-' + (start.getMonth() + 1) + '-' + start.getDate();
      end = end.getFullYear() + '-' + (end.getMonth() + 1) + '-' + end.getDate();
      this.object.ob_start_date = start;
      this.object.ob_end_date = end;
    },
    ok() {
      this.$emit('ok');
    },
    cancel() {
      this.$emit('cancel');
    }
  },
}
</script>
<style>
.cycle-range-picker {
  position: absolute;
  z-index: 2;
}
</style>